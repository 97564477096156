import Modal from "react-bootstrap/Modal";
import PDF from "../pdf";
import { connect } from "react-redux";
import { MdClose } from "react-icons/md";

const PdfPopUp = ({ selectedPdf, showModal, onHide, theme }) => {
  return (
    <Modal 
      className={`modal${theme === 'dark' ? '-dark' : ''}`}
      show={showModal}
      onHide={onHide}
      style={{ marginTop: "3%" }}
      size="xl"
      animation={false}
    >
      <Modal.Header>
        <Modal.Title>
          PDF
          <button className="custom-close-button" onClick={onHide}>
            <MdClose className="cross-icon" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "81vh" }}>
        <>
          {/* {selectedPdf && ( */}
            <div className={`pdf-modal ${theme === 'dark' ? 'pdf-false-dark' : ''}`}>
              {selectedPdf ? (
                <PDF file={selectedPdf} />
              ) : (
                <div className="downloading-center">Loading Document...</div>
              )}
            </div>
          {/* )} */}
        </>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
});

export default connect(mapStateToProps)(PdfPopUp);
