import * as types from "./types";

export const getDailyReport = () => ({
  type: types.FETCH_DAILY_REPORT_REQUEST,
});

export const setReportRowHeightAndMode = (id, tab, height, isExpand) => ({
  type: types.SET_REPORT_ROW_HEIGHT_AND_MODE,
  payload: { id, tab, height, isExpand },
});

export const getDocument = (payload) => ({
  type: types.FETCH_DAILY_REPORT_DOC_REQUEST,
  payload
});

export const getDailyReportWithParams = (params) => ({
  type: types.FETCH_DAILY_REPORT_REQUEST_PARAMS,
  payload: params,
})