import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { MdEditNote } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import Modal from "../../components/common/modal";

import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import MoonLoader from "react-spinners/MoonLoader";
import styled from "styled-components";

import { getOkcrAccounts, deleteUser } from "../../actions/okcrAction";

import * as api from "../../apis/instrument";

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
`;

const override = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: red;
`;

function OKCR({
  user,
  okcrAccounts,
  getOkcrAccounts,
  deleteUser,
  loading,
  theme,
}) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [pwd, setPWD] = useState("");
  const [errors, setErrors] = useState({});
  const [loginInfo, setLoginInfo] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getOkcrAccounts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (okcrAccounts.length === 0) {
      setIsEdit(false);
      setShow(true);
    } else {
      setIsEdit(true);
      setShow(false);
    }
  }, [okcrAccounts]);

  const login = async () => {
    if (!email || !pwd) {
      setLoginInfo(
        <div className="d-flex flex-column align-items-center">
          <h3 className="text-error">Email and Password are required</h3>
        </div>
      );
      return;
    } else {
      setErrors({});
    }
    const data = {
      username: email,
      password: pwd,
      runsheetEmail: user.username,
      sessionId: new Date().getTime(),
    };
    setLoginInfo(
      <div className="d-flex flex-column align-items-center">
        <h3>Validating Login Information...</h3>
        <MoonLoader size={25} />
      </div>
    );
    const loginRes = await api.doOkcrLogin(data);
    if (loginRes.success) {
      getOkcrAccounts();
      setLoginInfo("");
      setShow(false);
      resetLoginData();
    } else if (!loginRes.seccess && loginRes.error === "INVALID CREDENTIALS") {
      setLoginInfo(
        <div className="d-flex flex-column align-items-center">
          <h3 className="text-error">Unable to validate OKCR Login</h3>
        </div>
      );
    } else if (
      !loginRes.seccess &&
      loginRes.error === "DUPLICATE CREDENTIALS"
    ) {
      setLoginInfo(
        <div className="d-flex flex-column align-items-center">
          <h3 className="text-error">
            This OKCR account has already been added
          </h3>
        </div>
      );
    } else {
      setLoginInfo(
        <div className="d-flex flex-column align-items-center">
          <h3 className="text-error">An unexpected error has occured</h3>
        </div>
      );
    }
  };

  const resetLoginData = () => {
    setEmail("");
    setPWD("");
    setErrors({});
  };

  const showConfirmationBox = (username) => {
    setModalHeader("Confirm Deletion");
    setModalBody("Are you sure you want to delete this User Account?");
    setModalFooter(
      <div>
        <button
          className="btn btn-primary mr-3"
          onClick={() => {
            deleteUser({ username });
            setModalShow(false);
          }}
        >
          Yes
        </button>
        <button className="btn btn-info" onClick={() => setModalShow(false)}>
          Cancel
        </button>
      </div>
    );
    setModalShow(true);
  };

  const editUser = (username) => {
    setIsEdit(true);
    setEmail(username);
    setShow(true);
  };

  const accountsCols = [
    {
      headerName: "Username",
      field: "un",
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Number of Instruments ",
      field: "vw",
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "",
      resizable: false,
      minWidth: 120,
      maxWidth: 120,
      cellRenderer: ({ data }) => (
        <>
          <MdEditNote className="btn-icon" onClick={() => editUser(data.un)} />
          <IoMdClose
            className="btn-icon"
            onClick={() => showConfirmationBox(data.un)}
          />
        </>
      ),
    },
  ];

  return (
    <Container>
      {loading && <Overlay />}
      <SpinnerContainer>
        <BeatLoader
          css={override}
          size={20}
          color={"#123abc"}
          loading={loading}
        />
      </SpinnerContainer>
      <div
        className={`dashboard${
          theme === "dark" ? "-dark" : ""
        } d-flex flex-column`}
      >
        <NavBarContainer />

        <div className="d-flex justify-content-between">
          <div className="label">
            <span className="mb-1">OKCR Integration </span>
          </div>
          <button
            className="btn btn-primary right"
            onClick={() => {
              setIsEdit(false);
              setShow(true);
            }}
          >
            Add Account
          </button>
        </div>
        <div className="label"></div>
        <div className="content d-flex flex-column">
          <div className="data d-flex flex-column">
            <Table cols={accountsCols} data={okcrAccounts} />
          </div>
        </div>
        <Modal
          showModal={show}
          theme={theme}
          html={
            <div className="login">
              <div className="form">
                <h1 className="d-flex justify-content-center">
                  OK County Records Login
                </h1>
                <div className="mb-3">
                  <label>Email</label>
                  <input
                    placeholder="Ok County Records Email"
                    name="email"
                    className="form-control bg-transparent"
                    type="email"
                    autoComplete="off"
                    value={email}
                    readOnly={isEdit}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="fv-row mb-3">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Ok County Records Password"
                    autoComplete="new-password"
                    name="password"
                    className="form-control bg-transparent"
                    value={pwd}
                    onChange={(e) => setPWD(e.target.value)}
                  />
                  {errors.pwd && <span className="error">{errors.pwd}</span>}
                </div>
                <div className="d-grid mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={login}
                  >
                    Continue
                  </button>
                  {errors.general && (
                    <span className="error">{errors.general}</span>
                  )}
                  <button
                    type="submit"
                    className="btn btn-info mt-3"
                    onClick={() => {
                      setShow(false);
                      resetLoginData();
                    }}
                  >
                    Cancel
                  </button>
                  {loginInfo !== "" && <>{loginInfo}</>}
                </div>
              </div>
            </div>
          }
          onHide={() => setShow(false)}
        />
        <Modal
          showModal={modalShow}
          header={modalHeader}
          html={modalBody}
          footerHtml={modalFooter}
          onHide={() => setModalShow(false)}
          theme={theme}
        />
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    okcrAccounts: state.okcr.okcrAccounts,
    loading: state.okcr.loading,
    theme: state.user.theme,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOkcrAccounts: (data) => dispatch(getOkcrAccounts(data)),
  deleteUser: (data) => dispatch(deleteUser(data)),
});

const OKCRContainer = connect(mapStateToProps, mapDispatchToProps)(OKCR);

export default OKCRContainer;
