import { handleActions } from "redux-actions";
const defaultState = {
  __persist: false,
  reportData: {
    permits: [],
    completions: [],
    // applications: [],
    regulatory: [],
    welltransfers: [],
    spudnotice: [],
    refreshedat: [],
  },
  pdf: null,
  error: null,
  loading: false,
  formattedDate: null,
};

export default handleActions(
  {
    FETCH_DAILY_REPORT_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
        formattedDate: null,
      };
    },

    FETCH_DAILY_REPORT_REQUEST_PARAMS: (state) => {
      return {
        ...state,
        loading: true,  // Set loading to true when request with params is made
        error: null,    // Clear any previous error
        // formattedDate: null,  // Clear the formatted date
        reportData: {  // Reset report data to empty values
          permits: [],
          completions: [],
          regulatory: [],
          welltransfers: [],
          spudnotice: [],
          refreshedat: [],
        },
      };
    },

    FETCH_DAILY_REPORT_SUCCESS: (state, { payload: data }) => {
      const reportData = defaultState.reportData;
      let id = 0;
      let formattedDate;
      Object.keys(reportData).forEach((key) => {
        if (data[key]?.length) {
          if(key === 'refreshedat') {
            const dateStr = data[key][0].rt.replace("Z", ""); // Remove the 'Z' to avoid UTC issues
            const dateObj = new Date(dateStr);
            const options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true, // Use 12-hour format
            };
            formattedDate = dateObj.toLocaleString("en-US", options);
          } else {
            const repData = data[key].map((i) => {
              id++;
              return {
                ...i,
                rowId: id,
                ad: i.ad ? new Date(i.ad) : null,
                ed: i.ed ? new Date(i.ed) : null,
                pd: i.pd ? new Date(i.pd) : null,
                sd: i.sd ? new Date(i.sd) : null,
                cd: i.cd ? new Date(i.cd) : null,
                fd: i.fd ? new Date(i.fd) : null,
                md: i.md ? new Date(i.md) : null,
                dr: i.dr ? new Date(i.dr) : null,
                td: i.td ? new Date(i.td) : null,
                tda: i.tda ? new Date(i.tda) : null,
                expand: false,
              };
            });
            reportData[key] = repData;
          }
        } else {
          reportData[key] = [];
        }
      });
      return {
        ...state,
        reportData,
        formattedDate,
        loading: false,
      };
    },

    FETCH_DAILY_REPORT_FAILURE: (state, { payload: err }) => {
      return {
        ...state,
        reportData: defaultState.reportData,
        loading: false,
        error: err,
        formattedDate: null,
      };
    },

    SET_REPORT_ROW_HEIGHT_AND_MODE: (
      state,
      { payload: { id, tab, height, isExpand } }
    ) => {
      const udpatedReport = state.reportData;
      const data = udpatedReport[tab].map((row) => {
        if (row.rowId === id) {
          row.height = height;
          row.expand = isExpand;
        }
        return row;
      });
      udpatedReport[tab] = data;
      return {
        ...state,
        reportData: { ...udpatedReport },
      };
    },

    FETCH_DAILY_REPORT_DOC_REQUEST: (state) => {
      return {
        ...state,
        pdf: null,
      };
    },

    FETCH_DAILY_REPORT_DOC_SUCCESS: (state, { payload: { pdfData } }) => {
      const iPDF = window.URL.createObjectURL(
          new Blob([pdfData], { type: "application/pdf" })
      );
      return {
        ...state,
        pdf: iPDF,
        error: null,
      };
    },

    FETCH_DAILY_REPORT_DOC_FAILURE: (state, { payload: { err } }) => {
      return {
        ...state,
        pdf: null,
        error: err,
      };
    },
  },
  defaultState
);
