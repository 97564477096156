import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

const MyVerticalBarChart = ({ data, theme }) => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const aggregateEvents = (data) => {
      const userEventCounts = {};

      data.forEach((event) => {
        const user = event.UserName;
        const eventType = event.EventType;

        if (!userEventCounts[user]) {
          userEventCounts[user] = { 
            name: user, 
            Login: 0, 
            Logout: 0, 
            Search: 0, 
            OKCRIntegration: 0, 
            ViewedInstruments: 0,
            DownloadedInstruments: 0,
          };
        }

        if (eventType === 'Login') {
          userEventCounts[user].Login += 1;
        } else if (eventType === 'Logout') {
          userEventCounts[user].Logout += 1;
        } else if (eventType === 'Search') {
          userEventCounts[user].Search += 1;
        } else if (eventType === 'OKCR Integration') {
          userEventCounts[user].OKCRIntegration += 1;
        } else if (eventType === 'Viewed Instruments') { // Handle new event type
          userEventCounts[user].ViewedInstruments += 1;
        } else if (eventType === 'Downloaded Instruments') { // Handle new event type
          userEventCounts[user].DownloadedInstruments += 1;
        }
      });

      return Object.values(userEventCounts);
    };

    const aggregatedData = aggregateEvents(data);
    const sortedData = aggregatedData.sort((a, b) => b.Search - a.Search);
    setUserData(sortedData);
  }, [data]);

  const chartHeight = userData.length === 1 ? userData.length * 200 + 20 : userData.length * 120 + 300;

  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', paddingBottom: '40px' }}>
      <div style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '220px' }}>
        <ResponsiveContainer width="100%" height={chartHeight}>
          <BarChart
            data={userData}
            layout="vertical"
            margin={{ top: 40, right: 50, left: 50 }} 
            barCategoryGap={10}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis
              type="category"
              dataKey="name"
              tick={{ fontSize: 12, fill: theme === 'dark' ? '#ffffff' : '' }}
              width={200}
            />
            <XAxis
              type="number"
              tick={{ fontSize: 12, fill: theme === 'dark' ? '#ffffff' : '' }}
              orientation="top"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme === 'dark' ? '#46424f' : '#ffffff',
                border: 'none',
              }}
              itemStyle={{ color: theme === 'dark' ? '#ffffff' : '#000000' }}
              labelStyle={{ color: theme === 'dark' ? '#ffffff' : '#000000' }}
            />
            <Legend layout="horizontal" align="center" verticalAlign="top" wrapperStyle={{ top: 10 }} />

            <Bar dataKey="Login" fill="#4F7CAC" name="Login" barSize={20}>
              <LabelList
                dataKey="Login"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
            <Bar dataKey="Logout" fill="#14BDEB" name="Logout" barSize={20}>
              <LabelList
                dataKey="Logout"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
            <Bar dataKey="Search" fill="#090C9B" name="Search" barSize={20}>
              <LabelList
                dataKey="Search"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
            <Bar dataKey="OKCRIntegration" fill="#5448C8" name="OKCR Integration" barSize={20}>
              <LabelList
                dataKey="OKCRIntegration"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
            <Bar dataKey="ViewedInstruments" fill="#3c474b" name="Viewed Instruments" barSize={20}> {/* New bar */}
              <LabelList
                dataKey="ViewedInstruments"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
            <Bar dataKey="DownloadedInstruments" fill="#93C0A4" name="Downloaded Instruments" barSize={20}> {/* New bar */}
              <LabelList
                dataKey="DownloadedInstruments"
                position="inside"
                fill="#fff"
                fontSize="15px"
                formatter={(value) => {
                  return value >= 30 ? value : '';
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MyVerticalBarChart;
