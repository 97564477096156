import { connect } from "react-redux";
import { useState, useCallback, useEffect, useRef } from "react";
import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import SearchFilter from "../../components/searchFilter";
import ShowMoreButtonNewContainer from "../../components/common/moreButton/moreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../../components/common/modal";
import MapgroundNew from "../../components/common/MapgroundNew";
import { WithContext as ReactTags } from "react-tag-input";
import PdfModal from "../../components/common/pdfModal";
import ExcelJS from "exceljs";
import moment from "moment";
import ConfirmationModal from "../../components/common/confirmationModal";
import {
  getInstrumentListNew,
  getInstrumentPdf,
  downloadInstrumentPdf,
  removeTag,
  getPdfComments,
} from "../../actions/advancedSearchAction";

import { getOkcrAccounts } from "../../actions/okcrAction";

import { getInstruments, resetTrs } from "../../actions/instrumentAction";
import Split from "react-split";
import { Tab, Tabs } from "react-bootstrap";
import { dailyReportTabs } from "../dailyReport/tabDetails";
import PdfPopUp from "../../components/common/pdfPopUp";
import { getDocument } from "../../actions/dailyReport";

function AdvanceSearchNew({
  theme,
  instrumentList,
  getInstrumentListNew,
  getInstrumentPdf,
  getOkcrAccounts,
  downloadInstrumentPdf,
  loading,
  getInstruments,
  geojson,
  tags,
  removeTag,
  selectedPdf,
  getPdfComments,
  colorMapping,
  resetTrs,
  regulatoryAppsOrders,
  getDocument,
  selectedAppsTabPdf,
  ordersLoading,
}) {
  const div1Ref = useRef();
  const defaultSize = [53, 47];
  const [splitSizes, setSplitSizes] = useState(defaultSize);
  const [modalBody, setModalBody] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalFooter, setModalFooter] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [mapHeight, setMapHeight] = useState("300");
  const [isExpandTable, setIsExpandTable] = useState(false);
  const [trs, setTrs] = useState(null);
  const [clickedIds, setClickedIds] = useState([]);
  const [showPopPdf, setShowPopPdf] = useState(false);
  const [activeTab, setActiveTab] = useState("Land");

  useEffect(() => {
    const storedClickedIds = JSON.parse(localStorage.getItem("clickedIds")) || [];
    setClickedIds(storedClickedIds);
  }, []);

  const handleClick = (iid) => {
    let updatedClickedIds;
    if (clickedIds.includes(iid)) {
      updatedClickedIds = clickedIds.filter((id) => id !== iid);
    } else {
      updatedClickedIds = [...clickedIds, iid];
    }
    setClickedIds(updatedClickedIds);
    localStorage.setItem("clickedIds", JSON.stringify(updatedClickedIds)); 
  };

  const showConfirmationBox = (trs) => {
    setTrs(trs);
    setShowConfirmationModal(true);
  };

  const getInst = () => {
    getInstruments(trs.trim());
  };

  const showTagError = () => {
    setModalHeader("");
    setModalBody("This filter cannot be removed");
    setModalFooter(
      <div>
        <button className="btn btn-info" onClick={() => setShow(false)}>
          Cancel
        </button>
      </div>
    );
    setShow(true);
  };

  const fetchInstrumentList = useCallback(
    (filters, signal) => {
      getInstrumentListNew(filters, signal);
    },
    [getInstrumentListNew]
  );

  const openInstrument = (instrument, hd) => {
    setShowPdf(true);
    if (hd) getInstrumentPdf(instrument);
    else downloadInstrumentPdf(instrument);
  };

  const cols = [
    {
      headerName: "Instrument",
      field: "iid",
      width: 110,
      wrapHeaderText: true,
      cellRenderer: ({ data }) => {
        let content = '';
        if(data.hd) {
          content = 
          // eslint-disable-next-line
            (<a
              className={`check-pointer ${clickedIds.includes(data.iid) ? "clicked" : ""}`}
              style={{
                cursor: "pointer",
                color: clickedIds.includes(data.iid) ? "purple" : "",
              }}
              onClick={() => {
                handleClick(data.iid);
                openInstrument(data, true);
                getPdfComments({
                  co: data.co,
                  iid: data.iid,
                });
              }}
            >
              View
            </a>);
        } else {
          if(data.co === "CANADIAN" || data.co === "CADDO") {
            content = (<span style={{color: 'gray'}}>Unavailable</span>);
          } else {
            content = 
            // eslint-disable-next-line
            (<a
              href="#"
              onClick={() => {
                openInstrument(data, false);
              }}
            >
              Download
            </a>);
          }
        }
        return content;
      }
    },
    {
      headerName: "Instrument Type",
      field: "it",
      width: 150,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Type Normalize",
      field: "itn",
      width: 140,
      wrapHeaderText: true,
    },
    {
      headerName: "County",
      field: "co",
      width: 100,
      wrapHeaderText: true,
    },
    {
      headerName: "TRS",
      field: "trs",
      cellRenderer: ShowMoreButtonNewContainer,
      cellRendererParams: {
        field: "trs",
        length: 1,
        isAdvanced: true,
        isArray: true,
        isHyperlink: true,
        strLength: 10,
        callback: showConfirmationBox,
      },
      width: 140,
      wrapHeaderText: true,
    },
    { headerName: "Book", field: "bn", width: 80 },
    { headerName: "Page", field: "pn", width: 110 },
    {
      headerName: "Total Consideration",
      field: "ct",
      width: 130,
      wrapHeaderText: true,
      valueFormatter: ({ value }) => {
        value = parseFloat(value);
        if (isNaN(value)) {
          return "";
        } else {
          return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
        }
      },
    },
    {
      headerName: "Recorded Date",
      field: "dr",
      width: 110,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) =>
        value instanceof Date
          ? value.toLocaleDateString("en-US", { timeZone: "UTC" })
          : "",
    },
    {
      headerName: "Grantors",
      field: "gr",
      cellRenderer: ShowMoreButtonNewContainer,
      cellRendererParams: {
        field: "gr",
        length: 1,
        isAdvanced: true,
        isArray: true,
        strLength: 32,
      },
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Grantees",
      field: "ge",
      cellRenderer: ShowMoreButtonNewContainer,
      cellRendererParams: {
        field: "ge",
        length: 1,
        isAdvanced: true,
        isArray: true,
        strLength: 32,
      },
      flex: 1,
      wrapHeaderText: true,
    },
    { headerName: "Rank", field: "rnk", maxWidth: 80 },
    // {
    //   headerName: "",
    //   resizable: false,
    //   filter: false,
    //   maxWidth: 55,
    //   cellRenderer: ({ data }) =>
    //     (data.hd || data.co === "CANADIAN" || data.co === "CADDO") ? (
    //       ""
    //     ) : (
    //       <MdArrowCircleDown
    //         className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
    //         onClick={() => {
    //           openInstrument(data, false);
    //         }}
    //       />
    //     ),
    // },
  ];

  useEffect(() => {
    // reset the trs value on page load, so redirect to dashboard does not have pre loaded trs value
    resetTrs();
    let resizeTimeout = null;
    const observer = new ResizeObserver((entries) => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        for (const entry of entries) {
          const { height } = entry.contentRect;
          setMapHeight(height - 10);
        }
      }, 500);
    });

    const divElement = div1Ref.current;
    if (divElement) {
      observer.observe(divElement);
    }

    return () => {
      if (divElement) {
        observer.unobserve(divElement);
      }
    };
  }, [resetTrs]);
  const handleDelete = (index) => {
    const tag = tags[index];
    if (["from_date", "to_date"].includes(tag.id)) {
      showTagError();
    } else {
      removeTag(index);
    }
  };

  const downloadExcel = async () => {
    const data = [...instrumentList];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    // Set column widths
    worksheet.columns = [
      { header: "Instrument Number", key: "iid", width: 20 },
      { header: "Instrument Type", key: "it", width: 20 },
      { header: "Instrument Type Normalize", key: "itn", width: 30 },
      { header: "County", key: "co", width: 10 },
      { header: "TRS", key: "trs", width: 20 },
      { header: "Book", key: "bn", width: 10 },
      { header: "Page", key: "pn", width: 10 },
      { header: "Total Consideration", key: "ct", width: 20 },
      { header: "Recorded Date", key: "dr", width: 20 },
      { header: "Grantors", key: "gr", width: 30 },
      { header: "Grantees", key: "ge", width: 30 },
    ];

    // Add data to the worksheet
    data.forEach((item) => {
      worksheet.addRow(item);
    });

    // Enable text wrapping
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell((cell) => {
        cell.alignment = { wrapText: true };
      });
    });

    // Write to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `advance_search_(${moment().format("YYYY-MM-DD")}).xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getOkcrAccounts();
  }, [getOkcrAccounts]);

  return (
    <div
      className={`dashboard${
        theme === "dark" ? "-dark" : ""
      } d-flex flex-column`}
    >
      <NavBarContainer />
      <SearchFilter getFilteredData={fetchInstrumentList} />
      <Split
        sizes={splitSizes}
        minSize={0}
        className="split"
        direction="vertical"
      >
        <div ref={div1Ref}>
          <MapgroundNew
            theme={theme}
            geojson={geojson ?? []}
            height={mapHeight}
            setMapHeight={setSplitSizes}
            colorMapping={colorMapping}
            activeTab={activeTab}
            regulatoryGeojson={regulatoryAppsOrders}
          />
        </div>
        <div className="content d-flex flex-column">
          <div className="data d-flex flex-column">
            <div className="d-flex  align-items-center info-box">
              <div className="d-flex info-box">
              {/* <div>
                <span className="section mb-1">
                {loading ? "Fetching..." : `${instrumentList.length} records `}
              </span>
                  {instrumentList.length >= 10000 ? (
                    <span
                      style={{
                        position: "absolute",
                        top: "33px",
                        left: "25px",
                      }}
                    >
                      (Max Records)
                    </span>
                  ) : (
                    ""
                  )}
                </div> */}
                <div className="tags">
                  <ReactTags
                    tags={tags}
                    handleDelete={handleDelete}
                    allowDragDrop={false}
                  />
                </div>
              </div>
              {!loading && (
                <FontAwesomeIcon
                  className="btn icon btn-download"
                  icon={faDownload}
                  onClick={downloadExcel}
                />
              )}
              <FontAwesomeIcon
                className="btn icon"
                icon={isExpandTable ? faCompressArrowsAlt : faExpandArrowsAlt}
                onClick={() => {
                  if (isExpandTable) {
                    setSplitSizes(defaultSize);
                  } else {
                    setSplitSizes([0, 100]);
                  }
                  setIsExpandTable(!isExpandTable);
                }}
              />
            </div>

            {/* Tabs component */}
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              id="data-tabs"
            >
              <Tab eventKey="Land" 
                title={`Land (${loading ? "Fetching..." : instrumentList.length.toLocaleString()})`}
              >
                <div className="d-flex flex-column">
                  <div className="digital split-table d-flex flex-column">
                    <Table
                      cols={cols}
                      data={instrumentList}
                      showExport={true}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="regulatory"
                title={`Apps/Orders (${ordersLoading ? "Fetching..." : regulatoryAppsOrders.length.toLocaleString()})`}
                className="Apps-Orders"
              >
                  <Table
                    cols={[
                      {
                        headerName: "View",
                        field: "vw",
                        wrapHeaderText: true,
                        width:80,
                        cellRenderer: ({ data }) => (
                            <span
                              className="check-pointer"
                              style={{ color: '#009EF7' }}
                              onClick={() => {
                                setShowPopPdf(true);
                                getDocument({ tab: 'regulatory', eid: data.vw });
                              }}
                            >
                              View
                            </span>
                          ),
                      },
                      ...dailyReportTabs.find((tab) => tab.key === "regulatory")
                        .cols,
                    ]}
                    data={regulatoryAppsOrders.map((item) => ({
                      ...item,
                      dr: new Date(item.dr),
                      vw: item.vw, // Keep vw as is
                    }))}
                    columnDef={{ wrapText: true, autoHeight: true }}
                    height={42}
                  />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Split>

      <Modal
        showModal={show}
        header={modalHeader}
        html={modalBody}
        footerHtml={modalFooter}
        onHide={() => setShow(false)}
      />
      <ConfirmationModal
        showModal={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        getInstruments={getInst}
      />
      <PdfModal
        selectedPdf={selectedPdf}
        showModal={showPdf}
        onHide={() => setShowPdf(false)}
      ></PdfModal>
      <PdfPopUp
        selectedPdf={selectedAppsTabPdf}
        showModal={showPopPdf}
        onHide={() => setShowPopPdf(false)}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.user.theme,
    instrumentList: state.advancedSearch.instrumentList,
    geojson: state.advancedSearch.geojson,
    loading: state.advancedSearch.loading,
    tags: state.advancedSearch.tags,
    selectedPdf: state.advancedSearch.selectedPdf,
    colorMapping: state.advancedSearch.colorMapping,
    regulatoryAppsOrders: state.advancedSearch.regulatoryAppsOrders,
    selectedAppsTabPdf: state.dailyReport.pdf,
    ordersLoading: state.advancedSearch.ordersLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInstrumentListNew: (filters, signal) => dispatch(getInstrumentListNew(filters, signal)),
  getInstrumentPdf: (instrument) => dispatch(getInstrumentPdf(instrument)),
  downloadInstrumentPdf: (instrument) =>
  dispatch(downloadInstrumentPdf(instrument)),
  getInstruments: (trs) => dispatch(getInstruments(trs)),
  removeTag: (tagIndex) => dispatch(removeTag(tagIndex)),
  getPdfComments: (data) => dispatch(getPdfComments(data)),
  getOkcrAccounts: (data) => dispatch(getOkcrAccounts(data)),
  resetTrs: () => dispatch(resetTrs()),
  getDocument: (payload) => dispatch(getDocument(payload)),
});

const AdvanceSearchNewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceSearchNew);

export default AdvanceSearchNewContainer;