import axios from "axios";

const docApis = {
  regulatory: "/document/occregulatorydocument",
  permits: "/document/occpermitdocument",
}

export const doFetchDailyReport = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/getdailyreport", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doFetchDocument = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(docApis[payload.tab], payload, {
        baseURL: process.env.REACT_APP_API_URL,
        responseType: "arraybuffer",
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const doFetchDailyReportWithParams = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/search/getdailyreport", 
        params,
        {
          baseURL: process.env.REACT_APP_API_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        resolve(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

