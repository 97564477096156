const counties = [
  {
    name: 'Caddo',
    rings: [
      [
        [-98.623149, 35.551597],
        [-98.623336, 35.464225],
        [-98.618240, 35.117174],
        [-98.621054, 35.097352],
        [-98.619816, 34.854953],
        [-98.092645, 34.854919],
        [-98.096014, 35.377753],
        [-98.306326, 35.377239],
        [-98.313251, 35.551523],
        [-98.623149, 35.551597] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Comanche',
    rings: [
      [
        [-98.826008, 34.594413],
        [-98.826070, 34.507370],
        [-98.661982, 34.507460],
        [-98.661956, 34.405948],
        [-98.504988, 34.420452],
        [-98.295928, 34.449043],
        [-98.244120, 34.507121],
        [-98.142148, 34.506994],
        [-98.141799, 34.681158],
        [-98.089098, 34.681118],
        [-98.089010, 34.834806],
        [-98.092645, 34.854919],
        [-98.826041, 34.855122],
        [-98.826008, 34.594413] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Harper',
    rings: [
      [
        [-100.002572, 37.001619],
        [-100.003160, 36.593250],
        [-99.605565, 36.592753],
        [-99.295257, 36.595123],
        [-99.295186, 36.819566],
        [-99.374218, 36.879597],
        [-99.45620243467528, 36.9997002785008],
        [-99.541115, 36.999910],
        [-99.657658, 37.000197],
        [-99.995201, 37.001631],
        [-100.002572, 37.001619] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Alfalfa',
    rings: [
      [
        [-98.544662, 36.998524],
        [-98.533617, 36.463230],
        [-98.104427, 36.463105],
        [-98.104286, 36.593578],
        [-98.109393, 36.611543],
        [-98.111985, 36.998248],
        [-98.347149, 36.997969],
        [-98.354073, 36.997961],
        [-98.544662, 36.998524] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Ottawa',
    rings: [
      [
        [-95.007620, 36.999523],
        [-95.034123, 36.994225],
        [-94.999153, 36.943238],
        [-94.999403, 36.670631],
        [-94.617992, 36.667921],
        [-94.618307, 36.766560],
        [-94.617964, 36.998905],
        [-94.712770, 36.998794],
        [-94.995293, 36.999529],
        [-95.007620, 36.999523] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Adair',
    rings: [
      [
        [-94.797279, 36.161390],
        [-94.807297, 35.638604],
        [-94.4731193906467, 35.638547151635606],
        [-94.49304007242701, 35.75916647343223],
        [-94.494549, 35.768303],
        [-94.532071, 35.987852],
        [-94.551906, 36.102226],
        [-94.562268, 36.161973],
        [-94.797279, 36.161390] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Grady',
    rings: [
      [
        [-98.096014, 35.377753],
        [-98.092645, 34.854919],
        [-98.089010, 34.834806],
        [-98.089098, 34.681118],
        [-97.668425, 34.681344],
        [-97.668099, 34.855164],
        [-97.670629, 34.872960],
        [-97.671595, 35.335934],
        [-97.771765, 35.345735],
        [-97.833675, 35.335362],
        [-97.862805, 35.361830],
        [-97.955124, 35.346048],
        [-98.096014, 35.377753] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Canadian',
    rings: [
      [
        [-98.207104, 35.725140],
        [-98.313370, 35.725204],
        [-98.313251, 35.551523],
        [-98.306326, 35.377239],
        [-98.096014, 35.377753],
        [-97.955124, 35.346048],
        [-97.862805, 35.361830],
        [-97.833675, 35.335362],
        [-97.771765, 35.345735],
        [-97.671595, 35.335934],
        [-97.671529, 35.337508],
        [-97.671369, 35.377151],
        [-97.671383, 35.406352],
        [-97.674026, 35.725970],
        [-98.207104, 35.725140] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Cleveland',
    rings: [
      [
        [-97.671369, 35.377151],
        [-97.671529, 35.337508],
        [-97.556987, 35.288440],
        [-97.541460, 35.214165],
        [-97.407082, 35.138144],
        [-97.354192, 35.076656],
        [-97.333833, 34.958948],
        [-97.142349, 34.928176],
        [-97.142114, 35.376860],
        [-97.317832, 35.377286],
        [-97.423566, 35.377316],
        [-97.671369, 35.377151] // Closing the polygon
      ]
    ]
  },
  {
    name: 'Woods',
    rings: [
        [
            [-99.00030105388419, 36.99935809723729],
            [-99.129449, 36.99942199909289],
            [-99.407015, 36.99957899909289],
            [-99.45620243467528, 36.9997002785008],
            [-99.374218, 36.879596999093984],
            [-99.295186, 36.81956599909453],
            [-99.250383, 36.785014999094855],
            [-99.139855, 36.783039999094875],
            [-98.959973, 36.5989909990966],
            [-98.960334, 36.50691399909748],
            [-98.843646, 36.50624799909747],
            [-98.80782, 36.46074399909791],
            [-98.625361, 36.39259499909857],
            [-98.53355, 36.39759199909852],
            [-98.533617, 36.46322999909788],
            [-98.5446619733489, 36.9985242394935],
            [-98.791936, 36.9992549990929],
            [-99.00030105388419, 36.99935809723729] // Closing the polygon
        ]
    ]
},
{
    name: 'Garfield',
    rings: [
        [
            [-98.104286, 36.59357799909664],
            [-98.104427, 36.46310499909789],
            [-98.103904, 36.16487699910081],
            [-97.675617, 36.16466299910081],
            [-97.460792, 36.16446199910081],
            [-97.461603, 36.593636999096645],
            [-97.462459, 36.593626999096635],
            [-98.104286, 36.59357799909664] // Closing the polygon
        ]
    ]
},
{
    name: 'Nowata',
    rings: [
        [
            [-95.7867618707782, 36.99927091668489],
            [-95.80982, 36.94192999909342],
            [-95.809617, 36.597475999096616],
            [-95.43157, 36.5975399990966],
            [-95.431731, 36.9427759990934],
            [-95.40762000587709, 36.99934173573199],
            [-95.5224145523987, 36.99931978587579],
            [-95.573598, 36.999309999092894],
            [-95.7867618707782, 36.99927091668489] // Closing the polygon
        ]
    ]
},
{
    name: 'Blaine',
    rings: [
        [
            [-98.636899, 36.164893999100805],
            [-98.631985, 35.81240199910438],
            [-98.632166, 35.56370999910699],
            [-98.623149, 35.551596999107105],
            [-98.313251, 35.551522999107114],
            [-98.31337, 35.725203999105275],
            [-98.207104, 35.72513999910529],
            [-98.210544, 36.16488999910081],
            [-98.636899, 36.164893999100805] // Closing the polygon
        ]
    ]
},
{
    name: 'Seminole',
    rings: [
        [
            [-96.623699, 35.40072199910873],
            [-96.776707, 35.41137199910862],
            [-96.775681, 34.899957999114264],
            [-96.727591, 34.85718199911476],
            [-96.710934, 34.91171499911414],
            [-96.577089, 34.916583999114074],
            [-96.528587, 34.86958599911461],
            [-96.490786, 34.910584999114135],
            [-96.490434, 35.11585699911184],
            [-96.441401, 35.115769999111855],
            [-96.441368, 35.29012199910992],
            [-96.44129, 35.467711999108005],
            [-96.536718, 35.39077199910884],
            [-96.623699, 35.40072199910873] // Closing the polygon
        ]
    ]
},
{
    name: 'Pottawatomie',
    rings: [
        [
            [-97.142114, 35.376859999108994],
            [-97.142349, 34.928175999113954],
            [-97.01593, 34.90734799911418],
            [-96.930586, 34.96436399911354],
            [-96.8854, 34.92625099911397],
            [-96.813452, 34.94464899911376],
            [-96.775681, 34.899957999114264],
            [-96.776707, 35.41137199910862],
            [-96.623699, 35.40072199910873],
            [-96.624865, 35.46270599910807],
            [-97.14149, 35.463976999108056],
            [-97.142114, 35.376859999108994] // Closing the polygon
        ]
    ]
},
{
    name: 'Latimer',
    rings: [
        [
            [-95.349233, 35.05851399911248],
            [-95.507658, 35.02919699911281],
            [-95.514514, 34.68114199911678],
            [-95.059616, 34.68073599911676],
            [-95.059512, 34.855018999114776],
            [-94.99781, 34.85507799911477],
            [-94.99812, 34.942156999113784],
            [-94.927668, 34.942211999113795],
            [-94.927863, 35.058278999112495],
            [-95.349233, 35.05851399911248] // Closing the polygon
        ]
    ]
},
{
    name: 'McClain',
    rings: [
        [
            [-97.671529, 35.33750799910943],
            [-97.671595, 35.33593399910943],
            [-97.670629, 34.87295999911458],
            [-97.668099, 34.855163999114765],
            [-96.932471, 34.854454999114786],
            [-96.930806, 34.87508099911455],
            [-96.930586, 34.96436399911354],
            [-97.01593, 34.90734799911418],
            [-97.142349, 34.928175999113954],
            [-97.333833, 34.958947999113605],
            [-97.354192, 35.07665599911229],
            [-97.407082, 35.1381439991116],
            [-97.54146, 35.21416499911077],
            [-97.556987, 35.28843999910995],
            [-97.671529, 35.33750799910943] // Closing the polygon
        ]
    ]
},
{
    name: 'Johnston',
    rings: [
        [
            [-96.933457, 34.33266099912087],
            [-96.933671, 34.172552999122786],
            [-96.620784, 34.16204099912292],
            [-96.585355, 34.11445899912349],
            [-96.453839, 34.114405999123484],
            [-96.40764, 34.157316999122976],
            [-96.40733, 34.41839099911984],
            [-96.513786, 34.41956899911983],
            [-96.513865, 34.50538899911883],
            [-96.827288, 34.50598899911881],
            [-96.880814, 34.50605299911881],
            [-96.881005, 34.33297699912086],
            [-96.933457, 34.33266099912087] // Closing the polygon
        ]
    ]
},
// {
//     name: 'Kiowa',
//     rings: [
//         [
//             [-99.360226, 35.116737999111834],
//             [-99.408057, 35.11640199911189],
//             [-99.407686, 34.90570499911409],
//             [-99.233284, 34.90632099911412],
//             [-99.233101, 35.116502999111845],
//             [-99.360226, 35.116737999111834] // Closing the polygon
//         ]
//     ]
// },
// {
//     name: 'Osage',
//     rings: [
//         [
//             [-96.689169, 36.92973299909091],
//             [-96.690064, 36.847837999091034],
//             [-96.680949, 36.303915999097905],
//             [-96.426052, 36.303375999097925],
//             [-96.426275, 36.265341999098056],
//             [-96.200086, 36.26503899909804],
//             [-96.199113, 36.46704999909655],
//             [-96.189872, 36.65860799909534],
//             [-96.090766, 36.65843499909542],
//             [-96.090872, 36.77289199909473],
//             [-96.020556, 36.77193399909476],
//             [-96.022453, 36.971634999090596],
//             [-96.199252, 36.971971999090596],
//             [-96.689169, 36.92973299909091] // Closing the polygon
//         ]
//     ]
// },
{
    name: 'Hughes',
    rings: [
        [
            [-96.496196, 35.091152999113036],
            [-96.4964, 34.896092999114584],
            [-96.296328, 34.89630599911461],
            [-96.296618, 35.09424399911265],
            [-96.428725, 35.09112299911303],
            [-96.496196, 35.091152999113036] // Closing the polygon
        ]
    ]
},
// {
//     name: 'Noble',
//     rings: [
//         [
//             [-97.133844, 35.3400609991093],
//             [-97.146386, 35.078989999112436],
//             [-97.145126, 35.07630499911239],
//             [-97.145473, 35.05269999911259],
//             [-97.13963, 34.92633999911398],
//             [-97.046612, 34.92613999911398],
//             [-97.046292, 34.77244699911673],
//             [-97.073719, 34.77244699911673],
//             [-97.073857, 34.81965299911525],
//             [-97.136442, 34.81925299911529],
//             [-97.133844, 35.3400609991093] // Closing the polygon
//         ]
//     ]
// },
{
    name: 'Atoka',
    rings: [
        [
            [-96.186776, 34.226848999122484],
            [-96.186715, 34.16936199912343],
            [-96.112837, 34.169266999123434],
            [-96.1129, 34.26280999912224],
            [-96.135966, 34.26287999912223],
            [-96.185131, 34.26410399912215],
            [-96.186776, 34.226848999122484] // Closing the polygon
        ]
    ]
},
{
    name: 'Carter',
    rings: [
        [
            [-97.470468, 34.46603699911113],
            [-97.552517, 34.46615199911113],
            [-97.552747, 34.4812069991108],
            [-97.471964, 34.48141299911078],
            [-97.470468, 34.46603699911113] // Closing the polygon
        ]
    ]
},
{
  name: 'Major',
  rings: [
      [
          [-98.960334, 36.50691399909748],
          [-98.955849, 36.161576999100845],
          [-98.636899, 36.164893999100805],
          [-98.210544, 36.16488999910081],
          [-98.103904, 36.16487699910081],
          [-98.104427, 36.46310499909789],
          [-98.533617, 36.46322999909788],
          [-98.53355, 36.39759199909852],
          [-98.625361, 36.39259499909857],
          [-98.80782, 36.46074399909791],
          [-98.843646, 36.50624799909747],
          [-98.960334, 36.50691399909748] // Closing the polygon
      ]
  ]
},
{
  name: 'Craig',
  rings: [
      [
          [-95.40762000587709, 36.99934173573199],
          [-95.431731, 36.9427759990934],
          [-95.43157, 36.5975399990966],
          [-95.32837, 36.597629999096604],
          [-95.32817, 36.510239999097436],
          [-95.00573, 36.509889999097446],
          [-95.00569, 36.5976599990966],
          [-94.999403, 36.67063099909593],
          [-94.999153, 36.943237999093405],
          [-95.034123, 36.994224999092935],
          [-95.00762000447209, 36.99952255821869],
          [-95.0735039666303, 36.99948813377198],
          [-95.322565, 36.9993579990929],
          [-95.40762000587709, 36.99934173573199] // Closing the polygon
      ]
  ]
},
{
  name: 'Roger Mills',
  rings: [
      [
          [-100.00039619832901, 35.88094799910047],
          [-100.000392, 35.619114999106415],
          [-100.000388844143, 35.4223639874179],
          [-99.57582, 35.42165499910851],
          [-99.575598, 35.50886099910757],
          [-99.364141, 35.50879299910757],
          [-99.377052, 35.551740999107125],
          [-99.375817, 35.81218799910439],
          [-99.375906, 35.87504599910373],
          [-99.381082, 35.89882999910349],
          [-99.381022, 36.01292499910233],
          [-99.495812, 35.978731999102685],
          [-99.558486, 35.86598799910384],
          [-99.619813, 35.84208899910407],
          [-99.717101, 35.85976899910389],
          [-99.801389, 35.910644999103376],
          [-99.809444, 36.00004399910246],
          [-99.888309, 35.98784699910259],
          [-99.925147, 35.91366399910334],
          [-100.00039619832901, 35.88094799910047] // Closing the polygon
      ]
  ]
},
{
  name: 'Beckham',
  rings: [
      [
          [-100.000388844143, 35.4223639874179],
          [-100.000385, 35.18270199911112],
          [-100.00038402077301, 35.0303849991127],
          [-99.888571, 35.030261999112795],
          [-99.782949, 35.0301109991128],
          [-99.782727, 35.116779999111834],
          [-99.409291, 35.114508999111855],
          [-99.408338, 35.11648999911184],
          [-99.360226, 35.116737999111834],
          [-99.364217, 35.465327999108034],
          [-99.364141, 35.50879299910757],
          [-99.575598, 35.50886099910757],
          [-99.57582, 35.42165499910851],
          [-100.000388844143, 35.4223639874179] // Closing the polygon
      ]
  ]
},
{
  name: 'Tulsa',
  rings: [
      [
          [-96.297888, 36.162278999100835],
          [-96.297786, 36.0757789991017],
          [-96.029583, 36.075365999101706],
          [-96.029544, 35.98838699910257],
          [-96.02957, 35.90110599910347],
          [-96.033118, 35.85682399910392],
          [-95.819964, 35.855899999103926],
          [-95.761687, 35.90081099910348],
          [-95.76165, 36.162749999100825],
          [-95.8154, 36.16262999910083],
          [-95.812064, 36.24954399909997],
          [-95.81221, 36.35100999909898],
          [-95.79439, 36.41736999909833],
          [-95.81268, 36.42355999909827],
          [-96.001171, 36.423685999098275],
          [-96.001055, 36.16129399910084],
          [-96.268287, 36.161979999100836],
          [-96.297888, 36.162278999100835] // Closing the polygon
      ]
  ]
},
{
  name: 'Dewey',
  rings: [
      [
          [-99.382074, 36.164300999100824],
          [-99.381022, 36.01292499910233],
          [-99.381082, 35.89882999910349],
          [-99.375906, 35.87504599910373],
          [-99.375817, 35.81218799910439],
          [-98.631985, 35.81240199910438],
          [-98.636899, 36.164893999100805],
          [-98.955849, 36.161576999100845],
          [-99.382074, 36.164300999100824] // Closing the polygon
      ]
  ]
},
{
  name: 'Custer',
  rings: [
      [
          [-99.375817, 35.81218799910439],
          [-99.377052, 35.551740999107125],
          [-99.364141, 35.50879299910757],
          [-99.364217, 35.465327999108034],
          [-98.623336, 35.46422499910805],
          [-98.623149, 35.551596999107105],
          [-98.632166, 35.56370999910699],
          [-98.631985, 35.81240199910438],
          [-99.375817, 35.81218799910439] // Closing the polygon
      ]
  ]
},
{
  name: 'Pittsburg',
  rings: [
      [
          [-96.092001, 34.76747899911578],
          [-96.092043, 34.68055599911678],
          [-95.882103, 34.680474999116775],
          [-95.881987, 34.59352799911779],
          [-95.671976, 34.593851999117796],
          [-95.514315, 34.59415299911779],
          [-95.514514, 34.68114199911678],
          [-95.507658, 35.02919699911281],
          [-95.349233, 35.05851399911248],
          [-95.349339, 35.160295999111355],
          [-95.454954, 35.16057699911135],
          [-95.45125, 35.29601899910987],
          [-95.602342, 35.220738999110694],
          [-95.615664, 35.24969799911037],
          [-95.75597, 35.17719799911116],
          [-95.870663, 35.185985999111075],
          [-95.907966, 35.143493999111534],
          [-95.98371, 35.14522399911134],
          [-96.092001, 34.76747899911578] // Closing the polygon
      ]
  ]
},
{
  name: 'Grant',
  rings: [
    [
      [-98.11198518374579, 36.99824799372821],
      [-98.109393, 36.611542999096464],
      [-98.104286, 36.59357799909664],
      [-97.462459, 36.593626999096635],
      [-97.4623460298641, 36.9988242378898],
      [-97.768704, 36.9987499990929],
      [-97.8023129703503, 36.9986986084869],
      [-98.045342, 36.99832699909289],
      [-98.11198518374579, 36.99824799372821]
    ]
  ]
},
{
  name: 'Texas',
  rings: [
    [
      [-102.02820419104499, 36.99314514620124],
      [-102.032339018963, 36.50006567210763],
      [-101.826565, 36.49965399909753],
      [-101.623915, 36.499527999097545],
      [-101.085156, 36.499243999097544],
      [-100.95415215769302, 36.49952949572374],
      [-100.94546679307801, 36.99824682782171],
      [-101.06645021566, 36.99773617423331],
      [-101.211486, 36.99712399909291],
      [-101.485326, 36.995610999092925],
      [-101.55525884869098, 36.99529093837052],
      [-101.90244, 36.993701999092934],
      [-102.02820419104499, 36.99314514620124]
    ]
  ]
},
{
  name: 'Wagoner',
  rings: [
    [
      [-95.819964, 35.855899999103926],
      [-95.766166, 35.856402999103935],
      [-95.65042, 35.85675599910392],
      [-95.589736, 35.76515999910488],
      [-95.360363, 35.822410999104285],
      [-95.265679, 35.813265999104374],
      [-95.231459, 35.851194999103996],
      [-95.278701, 35.96410199910283],
      [-95.207946, 36.07470799910171],
      [-95.43997, 36.07519999910171],
      [-95.571525, 36.07528199910171],
      [-95.61852, 36.16251199910083],
      [-95.726408, 36.162495999100834],
      [-95.76165, 36.162749999100825],
      [-95.761687, 35.90081099910348],
      [-95.819964, 35.855899999103926]
    ]
  ]
},
{
  name: 'Okfuskee',
  rings: [
    [
      [-96.624865, 35.46270599910807],
      [-96.623699, 35.40072199910873],
      [-96.536718, 35.39077199910884],
      [-96.44129, 35.467711999108005],
      [-96.441368, 35.29012199910992],
      [-95.981376, 35.28989899910993],
      [-95.981307, 35.37682799910899],
      [-96.08753, 35.37687699910899],
      [-96.087795, 35.55211899910711],
      [-96.192844, 35.5519019991071],
      [-96.192563, 35.63908699910619],
      [-96.620648, 35.63900499910619],
      [-96.624865, 35.46270599910807]
    ]
  ]
},
{
  name: 'Okmulgee',
  rings: [
    [
      [-96.192563, 35.63908699910619],
      [-96.192844, 35.5519019991071],
      [-96.087795, 35.55211899910711],
      [-96.08753, 35.37687699910899],
      [-95.981307, 35.37682799910899],
      [-95.875626, 35.376689999109],
      [-95.875572, 35.463624999108056],
      [-95.823396, 35.463968999108054],
      [-95.822361, 35.551692999107125],
      [-95.712957, 35.55180199910711],
      [-95.713081, 35.72580699910528],
      [-95.766265, 35.72574999910529],
      [-95.766166, 35.856402999103935],
      [-95.819964, 35.855899999103926],
      [-96.033118, 35.85682399910392],
      [-96.141561, 35.85726199910393],
      [-96.192382, 35.857047999103926],
      [-96.192563, 35.63908699910619]
    ]
  ]
},
{
  name: 'Jackson',
  rings: [
    [
      [-99.8420638841464, 34.5069327680002],
      [-99.818186, 34.487839999119025],
      [-99.764882, 34.435265999119636],
      [-99.696462, 34.38103599912029],
      [-99.600026, 34.374687999120354],
      [-99.58448, 34.407672999119974],
      [-99.4750221370384, 34.39687098891061],
      [-99.470969, 34.39647099912011],
      [-99.420432, 34.380463999120295],
      [-99.394956, 34.44209899911957],
      [-99.350407, 34.437082999119625],
      [-99.27534, 34.386598999120224],
      [-99.2216079008455, 34.32537357349356],
      [-99.192747, 34.354860999120596],
      [-99.237922, 34.426656999119764],
      [-99.16332, 34.58803599911785],
      [-99.082881, 34.590853999117826],
      [-99.10315, 34.637949999117275],
      [-99.052511, 34.70155699911655],
      [-99.149114, 34.70235199911653],
      [-99.14457, 34.849539999114846],
      [-99.246462, 34.82211399911516],
      [-99.405821, 34.81303799911526],
      [-99.423201, 34.72501999911626],
      [-99.666849, 34.724554999116265],
      [-99.666512, 34.50712099911879],
      [-99.8420638841464, 34.5069327680002]
    ]
  ]
},
{
  name: 'Jefferson',
  rings: [
    [
      [-98.139226, 34.28977499912138],
      [-98.1384860257464, 34.14120601883427],
      [-98.123377, 34.15453999912301],
      [-98.099328, 34.10429499912362],
      [-98.099096, 34.0486389991243],
      [-98.082839, 34.00241199912487],
      [-98.005667, 33.995963999124946],
      [-97.947572, 33.99105299912501],
      [-97.953695, 33.92437299912582],
      [-97.95190595085518, 33.891225813983546],
      [-97.951215, 33.8784239991264],
      [-97.865765, 33.849392999126756],
      [-97.803473, 33.88018999912638],
      [-97.759834, 33.92520999912582],
      [-97.671772, 33.991369999125],
      [-97.609091, 33.96809299912529],
      [-97.596155, 33.92210599912587],
      [-97.56124315022109, 33.899061505859144],
      [-97.536818, 33.9838769991251],
      [- 97.560764, 34.07108899912402],
      [-97.562575, 34.28918199912138],
      [-98.139226, 34.28977499912138]
    ]
  ]
},
{
  name: 'Tillman',
  rings: [
    [
      [-99.10315, 34.637949999117275],
      [-99.082881, 34.590853999117826],
      [-99.16332, 34.58803599911785],
      [-99.237922, 34.426656999119764],
      [-99.192747, 34.354860999120596],
      [-99.2216079008455, 34.32537357349356],
      [-99.2116, 34.313969999121085],
      [-99.189511, 34.21431199912229],
      [-99.131553, 34.20935199912235],
      [-99.0588, 34.201255999122445],
      [-99.002916, 34.20878199912236],
      [-98.95232488752819, 34.2046698977259],
      [-98.94022, 34.20368599912242],
      [-98.872229, 34.160445999122935],
      [-98.80681, 34.155900999123],
      [-98.737232, 34.13099199912329],
      [-98.690072, 34.13315499912327],
      [-98.648073, 34.16444099912289],
      [-98.6103517816447, 34.15620993861969],
      [-98.60966, 34.33371999912085],
      [-98.662004, 34.33343099912086],
      [-98.661956, 34.405947999119995],
      [-98.661982, 34.5074599991188],
      [-98.82607, 34.50736999911881],
      [-98.826008, 34.594412999117786],
      [-99.001107, 34.594365999117784],
      [-99.001025, 34.63787199911727],
      [-99.10315, 34.637949999117275]
    ]
  ]
},
{
  name: 'Logan',
  rings: [
    [
      [-97.675617, 36.16466299910081],
      [-97.674026, 35.72596999910528],
      [-97.141072, 35.7244409991053],
      [-97.140583, 35.941990999103055],
      [-97.203592, 35.944794999103024],
      [-97.32362, 36.01087699910235],
      [-97.35387, 35.98493399910261],
      [-97.354132, 36.15907199910087],
      [-97.371722, 36.16428499910081],
      [-97.460792, 36.16446199910081],
      [-97.675617, 36.16466299910081]
    ]
  ]
},
{
  name: 'Garvin',
  rings: [
    [
      [-97.668099, 34.855163999114765],
      [-97.668425, 34.68134399911676],
      [-97.562757, 34.68114699911677],
      [-97.562323, 34.5070359991188],
      [-97.35211, 34.50678799911881],
      [-97.143958, 34.506531999118806],
      [-97.200073, 34.62276199911745],
      [-96.932328, 34.63681099911729],
      [-96.932471, 34.854454999114786],
      [-97.668099, 34.855163999114765]
    ]
  ]
},
{
  name: 'Osage',
  rings: [
    [
      [-96.749838, 36.99898799909289],
      [-96.752389, 36.782090999094876],
      [-96.889475, 36.75122999909516],
      [-96.931943, 36.68609699909578],
      [-97.064107, 36.684206999095785],
      [-97.057414, 36.59364699909664],
      [-96.912756, 36.59894899909659],
      [-96.888017, 36.57488599909682],
      [-97.009785, 36.506934999097474],
      [-96.940235, 36.45552499909798],
      [-96.875321, 36.47091499909782],
      [-96.821031, 36.53877099909717],
      [-96.753554, 36.562600999096944],
      [-96.706195, 36.477563999097754],
      [-96.710769, 36.43765599909813],
      [-96.635727, 36.42590799909826],
      [-96.575697, 36.374346999098755],
      [-96.518709, 36.37757599909872],
      [-96.573481, 36.315708999099314],
      [-96.515229, 36.29833199909949],
      [-96.457684, 36.332663999099154],
      [-96.433812, 36.28588899909961],
      [-96.326732, 36.2265509991002],
      [-96.256624, 36.22567599910021],
      [-96.268287, 36.161979999100836],
      [-96.001055, 36.16129399910084],
      [-96.001171, 36.423685999098275],
      [-96.0008102061996, 36.999201052020794],
      [-96.217571, 36.9990699990929],
      [-96.500288, 36.998642999092894],
      [-96.5255820442829, 36.9986779678177],
      [-96.749838, 36.99898799909289]
    ]
  ]
},
{
  name: 'Pawnee',
  rings: [
    [
      [-97.009785, 36.506934999097474],
      [-97.032118, 36.506956999097476],
      [-97.032106, 36.33335399909915],
      [-96.924936, 36.33334399909914],
      [-96.925069, 36.2464739991],
      [-96.818968, 36.246477999099994],
      [-96.819005, 36.158885999100875],
      [-96.621441, 36.15992899910086],
      [-96.598914, 36.16356899910082],
      [-96.297888, 36.162278999100835],
      [-96.268287, 36.161979999100836],
      [-96.256624, 36.22567599910021],
      [-96.326732, 36.2265509991002],
      [-96.433812, 36.28588899909961],
      [-96.457684, 36.332663999099154],
      [-96.515229, 36.29833199909949],
      [-96.573481, 36.315708999099314],
      [-96.518709, 36.37757599909872],
      [-96.575697, 36.374346999098755],
      [-96.635727, 36.42590799909826],
      [-96.710769, 36.43765599909813],
      [-96.706195, 36.477563999097754],
      [-96.753554, 36.562600999096944],
      [-96.821031, 36.53877099909717],
      [-96.875321, 36.47091499909782],
      [-96.940235, 36.45552499909798],
      [-97.009785, 36.506934999097474]
    ]
  ]
},
// {
//   name: 'Atoka',
//   rings: [
//     [
//       [-96.40733, 34.41839099911984],
//       [-96.40764, 34.157316999122976],
//       [-95.991578, 34.15680499912298],
//       [-95.779376, 34.15669899912297],
//       [-95.779425, 34.506555999118795],
//       [-95.6717, 34.50679899911881],
//       [-95.671976, 34.593851999117796],
//       [-95.881987, 34.59352799911779],
//       [-95.882103, 34.680474999116775],
//       [-96.092043, 34.68055599911678],
//       [-96.091873, 34.50615499911881],
//       [-96.145342, 34.50610199911881],
//       [-96.145096, 34.41867699911984],
//       [-96.40733, 34.41839099911984]
//     ]
//   ]
// },
{
  name: 'Choctaw',
  rings: [
    [
      [-95.991578, 34.15680499912298],
      [-95.93815, 34.12509999912337],
      [-95.918871, 33.925056999125815],
      [-95.78963931775229, 33.872437383798776],
      [-95.737508, 33.89596699912618],
      [-95.669978, 33.90584399912606],
      [-95.603657, 33.927194999125796],
      [-95.556915, 33.927019999125804],
      [-95.525322, 33.885486999126314],
      [-95.44737, 33.86884999912652],
      [-95.352338, 33.86778899912653],
      [-95.31044979544909, 33.87384303731196],
      [-95.283445, 33.87774599912641],
      [-95.253623, 33.929709999125755],
      [-95.226393, 33.96195399912537],
      [-95.1559076171075, 33.938482387695466],
      [-95.156521, 34.156964999122984],
      [-95.779376, 34.15669899912297],
      [-95.991578, 34.15680499912298]
    ]
  ]
},
// {
//   name: 'Hughes',
//   rings: [
//     [
//       [-96.441368, 35.29012199910992],
//       [-96.441401, 35.115769999111855],
//       [-96.490434, 35.11585699911184],
//       [-96.490786, 34.910584999114135],
//       [-96.408639, 34.930672999113916],
//       [-96.406483, 34.76758999911578],
//       [-96.092001, 34.76747899911578],
//       [-96.088861, 35.04975799911258],
//       [-95.98371, 35.151556999111456],
//       [-95.981376, 35.28989899910993],
//       [-96.441368, 35.29012199910992]
//     ]
//   ]
// },
{
  name: 'McIntosh',
  rings: [
    [
      [-95.981376, 35.28989899910993],
      [-95.98371, 35.151556999111456],
      [-95.907966, 35.143493999111534],
      [-95.870663, 35.185985999111075],
      [-95.75597, 35.17719799911116],
      [-95.615664, 35.24969799911037],
      [-95.602342, 35.220738999110694],
      [-95.45125, 35.29601899910987],
      [-95.390252, 35.32749699910952],
      [-95.34465, 35.2930969991099],
      [-95.34503, 35.55224099910711],
      [-95.712957, 35.55180199910711],
      [-95.822361, 35.551692999107125],
      [-95.823396, 35.463968999108054],
      [-95.875572, 35.463624999108056],
      [-95.875626, 35.376689999109],
      [-95.981307, 35.37682799910899],
      [-95.981376, 35.28989899910993]
    ]
  ]
},
{
  name: 'Haskell',
  rings: [
    [
      [-95.45125, 35.29601899910987],
      [-95.454954, 35.16057699911135],
      [-95.349339, 35.160295999111355],
      [-95.349233, 35.05851399911248],
      [-94.927863, 35.058278999112495],
      [-94.927936, 35.20261099911089],
      [-94.814284, 35.202330999110906],
      [-94.813629, 35.32334399910957],
      [-94.828972, 35.361545999109154],
      [-94.914454, 35.347524999109304],
      [-94.901832, 35.397939999108765],
      [-95.00651, 35.41459999910858],
      [-95.049933, 35.458893999108106],
      [-95.170462, 35.310105999109716],
      [-95.240718, 35.262309999110236],
      [-95.34465, 35.2930969991099],
      [-95.390252, 35.32749699910952],
      [-95.45125, 35.29601899910987]
    ]
  ]
},
{
  name: 'Le Flore',
  rings: [
    [
      [-94.927863, 35.058278999112495],
      [-94.927668, 34.942211999113795],
      [-94.99812, 34.942156999113784],
      [-94.99781, 34.85507799911477],
      [-95.059512, 34.855018999114776],
      [-95.059616, 34.68073599911676],
      [-94.937752, 34.68087699911676],
      [-94.937605, 34.506830999118804],
      [-94.4611686861559, 34.507456650691296],
      [-94.4575, 34.63494499911731],
      [-94.45439503413789, 34.728958672295626],
      [-94.449058, 34.890555999114376],
      [-94.44750953578429, 34.93397621127108],
      [-94.44292586169001, 35.06250620868514],
      [-94.435316, 35.27589299911007],
      [-94.431515, 35.369590999109064],
      [-94.4339134438324, 35.38636176900759],
      [-94.49237, 35.3017759991098],
      [-94.594801, 35.32797399910952],
      [-94.668796, 35.295028999109874],
      [-94.750224, 35.356968999109206],
      [-94.813629, 35.32334399910957],
      [-94.814284, 35.202330999110906],
      [-94.927936, 35.20261099911089],
      [-94.927863, 35.058278999112495]
    ]
  ]
},
{
  name: 'Muskogee',
  rings: [
    [
      [-95.766166, 35.856402999103935],
      [-95.766265, 35.72574999910529],
      [-95.713081, 35.72580699910528],
      [-95.712957, 35.55180199910711],
      [-95.34503, 35.55224099910711],
      [-95.34465, 35.2930969991099],
      [-95.240718, 35.262309999110236],
      [-95.170462, 35.310105999109716],
      [-95.049933, 35.458893999108106],
      [-95.085635, 35.46139599910808],
      [-95.132437, 35.526195999107394],
      [-95.127464, 35.638893999106195],
      [-95.127163, 35.81275499910438],
      [-95.265679, 35.813265999104374],
      [-95.360363, 35.822410999104285],
      [-95.589736, 35.76515999910488],
      [-95.65042, 35.85675599910392],
      [-95.766166, 35.856402999103935]
    ]
  ]
},
{
  name: 'Sequoyah',
  rings: [
    [
      [-95.127464, 35.638893999106195],
      [-95.132437, 35.526195999107394],
      [-95.085635, 35.46139599910808],
      [-95.049933, 35.458893999108106],
      [-95.00651, 35.41459999910858],
      [-94.901832, 35.397939999108765],
      [-94.914454, 35.347524999109304],
      [-94.828972, 35.361545999109154],
      [-94.813629, 35.32334399910957],
      [-94.750224, 35.356968999109206],
      [-94.668796, 35.295028999109874],
      [-94.594801, 35.32797399910952],
      [-94.49237, 35.3017759991098],
      [-94.4339134438324, 35.38636176900759],
      [-94.43488928759669, 35.3931852146963],
      [-94.449696, 35.4967189991077],
      [-94.4731193906467, 35.638547151635606],
      [-94.807297, 35.6386039991062],
      [-95.127464, 35.638893999106195]
    ]
  ]
},
{
  name: 'Delaware',
  rings: [
    [
      [-95.00573, 36.509889999097446],
      [-95.011303, 36.16181499910085],
      [-94.797279, 36.16138999910084],
      [-94.56226792855279, 36.16197261372724],
      [-94.5862, 36.29996899909947],
      [-94.617919, 36.49941399909754],
      [-94.617815, 36.612603999096464],
      [-94.61799177841719, 36.66792127144016],
      [-94.999403, 36.67063099909593],
      [-95.00569, 36.5976599990966],
      [-95.00573, 36.509889999097446]
    ]
  ]
},
{
  name: 'McCurtain',
  rings: [
    [
      [-95.156521, 34.156964999122984],
      [-95.1559076171075, 33.938482387695466],
      [-95.149462, 33.93633599912568],
      [-95.095002, 33.90481599912607],
      [-95.046568, 33.8625649991266],
      [-94.98165, 33.85228399912673],
      [-94.93956, 33.810502999127245],
      [-94.902276, 33.77628899912767],
      [-94.841634, 33.73943099912813],
      [-94.766146, 33.748030999128034],
      [-94.7319329019959, 33.72083051540868],
      [-94.714865, 33.707260999128536],
      [-94.630586, 33.673400999128965],
      [-94.572872, 33.669885999129],
      [-94.528928, 33.62183999912961],
      [-94.485875, 33.637866999129415],
      [-94.481842, 33.789007999127506],
      [-94.4772667415298, 33.94091092706043],
      [-94.474895, 34.019654999124654],
      [-94.47015267816208, 34.18986429124869],
      [-94.465425, 34.35954799912055],
      [-94.4611686861559, 34.507456650691296],
      [-94.937605, 34.506830999118804],
      [-95.046746, 34.50722899911881],
      [-95.046585, 34.24367099912194],
      [-95.151233, 34.24390599912192],
      [-95.156521, 34.156964999122984]
    ]
  ]
},
{
  name: 'Harmon',
  rings: [
    [
      [-100.00038402077301, 35.0303849991127],
      [-100.00038219481601, 34.74636055167762],
      [-100.000381, 34.56050899911817],
      [-99.997629328163, 34.561136623678266],
      [-99.929334, 34.576713999117985],
      [-99.8420638841464, 34.5069327680002],
      [-99.666512, 34.50712099911879],
      [-99.666849, 34.724554999116265],
      [-99.720066, 34.76844999911577],
      [-99.731092, 34.94405799911377],
      [-99.888425, 34.943945999113765],
      [-99.888571, 35.030261999112795],
      [-100.00038402077301, 35.0303849991127]
    ]
  ]
},
{
  name: 'Love',
  rings: [
    [
      [-97.56124315022109, 33.899061505859144],
      [-97.55827, 33.897098999126165],
      [-97.486505, 33.91699399912592],
      [-97.4841434385876, 33.91388910801686],
      [-97.451469, 33.870929999126496],
      [-97.444193, 33.82377299912708],
      [-97.372941, 33.81945399912714],
      [-97.318243, 33.865120999126546],
      [-97.24618, 33.900343999126115],
      [-97.206141, 33.91427999912595],
      [-97.166629, 33.84731099912678],
      [-97.205652, 33.80982399912725],
      [-97.149394, 33.721966999128355],
      [-97.091072, 33.7351149991282],
      [-97.087852, 33.7740989991277],
      [-97.07859, 33.812755999127226],
      [-97.055838, 33.85574099912669],
      [-96.985567, 33.886521999126295],
      [-96.988745, 33.9184679991259],
      [-96.952313, 33.94458199912558],
      [-96.94461688837049, 33.94501324957959],
      [-96.934343303133, 33.94558892837836],
      [-96.934791, 34.01294199912473],
      [-96.969627, 34.07101999912402],
      [-97.560764, 34.07108899912402],
      [-97.536818, 33.9838769991251],
      [-97.56124315022109, 33.899061505859144]
    ]
  ]
},
// {
//   name: 'Carter',
//   rings: [
//     [
//       [-97.562323, 34.5070359991188],
//       [-97.562575, 34.28918199912138],
//       [-97.560764, 34.07108899912402],
//       [-96.969627, 34.07101999912402],
//       [-96.968415, 34.17261699912279],
//       [-96.933671, 34.172552999122786],
//       [-96.933457, 34.33266099912087],
//       [-97.028704, 34.33263799912086],
//       [-97.036488, 34.37541499912036],
//       [-97.352158, 34.37622699912035],
//       [-97.35211, 34.50678799911881],
//       [-97.562323, 34.5070359991188]
//     ]
//   ]
// },
{
  name: 'Washington',
  rings: [
    [
      [-96.0008102061996, 36.999201052020794],
      [-96.001171, 36.423685999098275],
      [-95.81268, 36.42355999909827],
      [-95.812512, 36.580507999096774],
      [-95.809617, 36.597475999096616],
      [-95.80982, 36.94192999909342],
      [-95.7867618707782, 36.99927091668489],
      [-95.928122, 36.99924499909289],
      [-95.96427123210219, 36.99922314337529],
      [-96.0008102061996, 36.999201052020794]
    ]
  ]
},
{
  name: 'Beaver',
  rings: [
    [
      [-100.94546679307801, 36.99824682782171],
      [-100.95415215769302, 36.49952949572374],
      [-100.884174, 36.49968199909754],
      [-100.592614, 36.49946899909754],
      [-100.54614512609702, 36.49950513840134],
      [-100.311018, 36.49968799909754],
      [-100.00376199336802, 36.499701847835134],
      [-100.00316, 36.59324999909665],
      [-100.00257151432199, 37.001619153004874],
      [-100.089481743903, 37.00147946855637],
      [-100.552683, 37.000734999092884],
      [-100.633324986158, 37.00017360814578],
      [-100.855634, 36.998625999092894],
      [-100.94546679307801, 36.99824682782171]
    ]
  ]
},
{
  name: 'Pontotoc',
  rings: [
    [
      [-96.932471, 34.854454999114786],
      [-96.932328, 34.63681099911729],
      [-96.932423, 34.5932609991178],
      [-96.82729, 34.593219999117785],
      [-96.827288, 34.50598899911881],
      [-96.513865, 34.50538899911883],
      [-96.512171, 34.68006499911678],
      [-96.406357, 34.680022999116794],
      [-96.406483, 34.76758999911578],
      [-96.408639, 34.930672999113916],
      [-96.490786, 34.910584999114135],
      [-96.528587, 34.86958599911461],
      [-96.577089, 34.916583999114074],
      [-96.710934, 34.91171499911414],
      [-96.727591, 34.85718199911476],
      [-96.775681, 34.899957999114264],
      [-96.813452, 34.94464899911376],
      [-96.8854, 34.92625099911397],
      [-96.930586, 34.96436399911354],
      [-96.930806, 34.87508099911455],
      [-96.932471, 34.854454999114786]
    ]
  ]
},
{
  name: 'Coal',
  rings: [
    [
      [-96.513865, 34.50538899911883],
      [-96.513786, 34.41956899911983],
      [-96.40733, 34.41839099911984],
      [-96.145096, 34.41867699911984],
      [-96.145342, 34.50610199911881],
      [-96.091873, 34.50615499911881],
      [-96.092043, 34.68055599911678],
      [-96.092001, 34.76747899911578],
      [-96.406483, 34.76758999911578],
      [-96.406357, 34.680022999116794],
      [-96.512171, 34.68006499911678],
      [-96.513865, 34.50538899911883]
    ]
  ]
},
{
  name: 'Cotton',
  rings: [
    [
      [-98.661956, 34.405947999119995],
      [-98.662004, 34.33343099912086],
      [-98.60966, 34.33371999912085],
      [-98.6103517816447, 34.15620993861969],
      [-98.577136, 34.14896199912308],
      [-98.5282, 34.094960999123735],
      [-98.475066, 34.0642689991241],
      [-98.423533, 34.0819494751298],
      [-98.414426, 34.08507399912386],
      [-98.398441, 34.128455999123325],
      [-98.364023, 34.157108999122975],
      [-98.31875, 34.1464209991231],
      [-98.225282, 34.127244999123334],
      [-98.16912, 34.114170999123495],
      [-98.1384860257464, 34.14120601883427],
      [-98.139226, 34.28977499912138],
      [-98.139293, 34.48927899911901],
      [-98.142148, 34.5069939991188],
      [-98.24412, 34.50712099911879],
      [-98.295928, 34.44904299911948],
      [-98.504988, 34.42045199911982],
      [-98.661956, 34.405947999119995]
    ]
  ]
},
{
  name: 'Kay',
  rings: [
    [
      [-97.4623460298641, 36.9988242378898],
      [-97.462459, 36.593626999096635],
      [-97.461603, 36.593636999096645],
      [-97.057414, 36.59364699909664],
      [-97.064107, 36.684206999095785],
      [-96.931943, 36.68609699909578],
      [-96.889475, 36.75122999909516],
      [-96.752389, 36.782090999094876],
      [-96.749838, 36.99898799909289],
      [-97.100652, 36.9989979990929],
      [-97.14772096692269, 36.99897233471439],
      [-97.384925, 36.99884299909289],
      [-97.4623460298641, 36.9988242378898]
    ]
  ]
},
{
  name: 'Greer',
  rings: [
    [
      [-99.888571, 35.030261999112795],
      [-99.888425, 34.943945999113765],
      [-99.731092, 34.94405799911377],
      [-99.720066, 34.76844999911577],
      [-99.666849, 34.724554999116265],
      [-99.423201, 34.72501999911626],
      [-99.405821, 34.81303799911526],
      [-99.246462, 34.82211399911516],
      [-99.30622, 34.857631999114744],
      [-99.327788, 35.041696999112666],
      [-99.368367, 35.08786699911215],
      [-99.409291, 35.114508999111855],
      [-99.782727, 35.116779999111834],
      [-99.782949, 35.0301109991128],
      [-99.888571, 35.030261999112795]
    ]
  ]
},
{
  name: 'Pushmataha',
  rings: [
    [
      [-95.671976, 34.593851999117796],
      [-95.6717, 34.50679899911881],
      [-95.779425, 34.506555999118795],
      [-95.779376, 34.15669899912297],
      [-95.156521, 34.156964999122984],
      [-95.151233, 34.24390599912192],
      [-95.046585, 34.24367099912194],
      [-95.046746, 34.50722899911881],
      [-94.937605, 34.506830999118804],
      [-94.937752, 34.68087699911676],
      [-95.059616, 34.68073599911676],
      [-95.514514, 34.68114199911678],
      [-95.514315, 34.59415299911779],
      [-95.671976, 34.593851999117796]
    ]
  ]
},
{
  name: 'Mayes',
  rings: [
    [
      [-95.32817, 36.510239999097436],
      [-95.43561, 36.51015999909743],
      [-95.43997, 36.07519999910171],
      [-95.207946, 36.07470799910171],
      [-95.118377, 36.0745439991017],
      [-95.1185, 36.16162999910084],
      [-95.011303, 36.16181499910085],
      [-95.00573, 36.509889999097446],
      [-95.32817, 36.510239999097436]
    ]
  ]
},
{
  name: 'Kingfisher',
  rings: [
    [
      [-98.210544, 36.16488999910081],
      [-98.207104, 35.72513999910529],
      [-97.674026, 35.72596999910528],
      [-97.675617, 36.16466299910081],
      [-98.103904, 36.16487699910081],
      [-98.210544, 36.16488999910081]
    ]
  ]
},
{
  name: 'Rogers',
  rings: [
    [
      [-95.81268, 36.42355999909827],
      [-95.79439, 36.41736999909833],
      [-95.81221, 36.35100999909898],
      [-95.812064, 36.24954399909997],
      [-95.8154, 36.16262999910083],
      [-95.76165, 36.162749999100825],
      [-95.726408, 36.162495999100834],
      [-95.61852, 36.16251199910083],
      [-95.571525, 36.07528199910171],
      [-95.43997, 36.07519999910171],
      [-95.43561, 36.51015999909743],
      [-95.32817, 36.510239999097436],
      [-95.32837, 36.597629999096604],
      [-95.43157, 36.5975399990966],
      [-95.809617, 36.597475999096616],
      [-95.812512, 36.580507999096774],
      [-95.81268, 36.42355999909827]
    ]
  ]
},
{
  name: 'Noble',
  rings: [
    [
      [-97.461603, 36.593636999096645],
      [-97.460792, 36.16446199910081],
      [-97.371722, 36.16428499910081],
      [-97.354132, 36.15907199910087],
      [-97.140668, 36.15923099910088],
      [-97.140968, 36.246434999099996],
      [-96.925069, 36.2464739991],
      [-96.924936, 36.33334399909914],
      [-97.032106, 36.33335399909915],
      [-97.032118, 36.506956999097476],
      [-97.009785, 36.506934999097474],
      [-96.888017, 36.57488599909682],
      [-96.912756, 36.59894899909659],
      [-97.057414, 36.59364699909664],
      [-97.461603, 36.593636999096645]
    ]
  ]
},
{
  name: 'Washita',
  rings: [
    [
      [-99.364217, 35.465327999108034],
      [-99.360226, 35.116737999111834],
      [-98.750344, 35.11634299911185],
      [-98.651267, 35.12418099911176],
      [-98.621054, 35.09735199911206],
      [-98.61824, 35.11717399911183],
      [-98.623336, 35.46422499910805],
      [-99.364217, 35.465327999108034]
    ]
  ]
},
{
  name: 'Cherokee',
  rings: [
    [
      [-95.207946, 36.07470799910171],
      [-95.278701, 35.96410199910283],
      [-95.231459, 35.851194999103996],
      [-95.265679, 35.813265999104374],
      [-95.127163, 35.81275499910438],
      [-95.127464, 35.638893999106195],
      [-94.807297, 35.6386039991062],
      [-94.797279, 36.16138999910084],
      [-95.011303, 36.16181499910085],
      [-95.1185, 36.16162999910084],
      [-95.118377, 36.0745439991017],
      [-95.207946, 36.07470799910171]
    ]
  ]
},
{
  name: 'Cimarron',
  rings: [
    [
      [-103.002199, 37.000103999092886],
      [-103.001964, 36.90957299909371],
      [-103.002518, 36.67518599909587],
      [-103.002188, 36.60271599909656],
      [-103.002434, 36.500396999097525],
      [-102.162463, 36.500325999097534],
      [-102.032339018963, 36.50006567210763],
      [-102.02820419104499, 36.99314514620124],
      [-102.04224, 36.993082999092934],
      [-102.355288, 36.994505999092944],
      [-102.698142, 36.99514899909292],
      [-102.841989, 36.99959799909289],
      [-103.002199, 37.000103999092886]
    ]
  ]
},
{
  name: 'Marshall',
  rings: [
    [
      [-96.933671, 34.172552999122786],
      [-96.968415, 34.17261699912279],
      [-96.969627, 34.07101999912402],
      [-96.934791, 34.01294199912473],
      [-96.934343303133, 33.94558892837836],
      [-96.905253, 33.947218999125546],
      [-96.897194, 33.9029539991261],
      [-96.850593, 33.8472109991268],
      [-96.794276, 33.86888599912651],
      [-96.776766, 33.84197599912685],
      [-96.712422, 33.83163299912698],
      [-96.682103, 33.87664499912642],
      [-96.659896, 33.91666599912592],
      [-96.5946743073771, 33.88301865652465],
      [-96.57701, 33.96440599912534],
      [-96.624803, 34.00972399912477],
      [-96.548028, 34.06330199912413],
      [-96.585355, 34.11445899912349],
      [-96.620784, 34.16204099912292],
      [-96.933671, 34.172552999122786]
    ]
  ]
},
{
  name: 'Murray',
  rings: [
    [
      [-97.35211, 34.50678799911881],
      [-97.352158, 34.37622699912035],
      [-97.036488, 34.37541499912036],
      [-97.028704, 34.33263799912086],
      [-96.933457, 34.33266099912087],
      [-96.881005, 34.33297699912086],
      [-96.880814, 34.50605299911881],
      [-96.827288, 34.50598899911881],
      [-96.82729, 34.593219999117785],
      [-96.932423, 34.5932609991178],
      [-96.932328, 34.63681099911729],
      [-97.200073, 34.62276199911745],
      [-97.143958, 34.506531999118806],
      [-97.35211, 34.50678799911881]
    ]
  ]
},
{
  name: 'Woodward',
  rings: [
    [
      [-99.605565, 36.592752999096646],
      [-99.596079, 36.16406099910081],
      [-99.382074, 36.164300999100824],
      [-98.955849, 36.161576999100845],
      [-98.960334, 36.50691399909748],
      [-98.959973, 36.5989909990966],
      [-99.139855, 36.783039999094875],
      [-99.250383, 36.785014999094855],
      [-99.295186, 36.81956599909453],
      [-99.295257, 36.59512299909663],
      [-99.605565, 36.592752999096646]
    ]
  ]
},
{
  name: 'Lincoln',
  rings: [
    [
      [-97.141072, 35.7244409991053],
      [-97.14149, 35.463976999108056],
      [-96.624865, 35.46270599910807],
      [-96.620648, 35.63900499910619],
      [-96.621097, 35.94151899910306],
      [-97.140583, 35.941990999103055],
      [-97.141072, 35.7244409991053]
    ]
  ]
},
{
  name: 'Creek',
  rings: [
    [
      [-96.621441, 36.15992899910086],
      [-96.621097, 35.94151899910306],
      [-96.620648, 35.63900499910619],
      [-96.192563, 35.63908699910619],
      [-96.192382, 35.857047999103926],
      [-96.141561, 35.85726199910393],
      [-96.033118, 35.85682399910392],
      [-96.02957, 35.90110599910347],
      [-96.029544, 35.98838699910257],
      [-96.029583, 36.075365999101706],
      [-96.297786, 36.0757789991017],
      [-96.297888, 36.162278999100835],
      [-96.598914, 36.16356899910082],
      [-96.621441, 36.15992899910086]
    ]
  ]
},
{
  name: 'Stephens',
  rings: [
    [
      [-98.089098, 34.68111899911676],
      [-98.141799, 34.68115799911676],
      [-98.142148, 34.5069939991188],
      [-98.139293, 34.48927899911901],
      [-98.139226, 34.28977499912138],
      [-97.562575, 34.28918199912138],
      [-97.562323, 34.5070359991188],
      [-97.562757, 34.68114699911677],
      [-97.668425, 34.68134399911676],
      [-98.089098, 34.68111899911676]
    ]
  ]
},
{
  name: 'Ellis',
  rings: [
    [
      [-100.00316, 36.59324999909665],
      [-100.00376199336802, 36.499701847835134],
      [-100.000406, 36.49970199909754],
      [-100.000399, 36.05567699910189],
      [-100.00039619832901, 35.88094799910047],
      [-99.925147, 35.91366399910334],
      [-99.888309, 35.98784699910259],
      [-99.809444, 36.00004399910246],
      [-99.801389, 35.910644999103376],
      [-99.717101, 35.85976899910389],
      [-99.619813, 35.84208899910407],
      [-99.558486, 35.86598799910384],
      [-99.495812, 35.978731999102685],
      [-99.381022, 36.01292499910233],
      [-99.382074, 36.164300999100824],
      [-99.596079, 36.16406099910081],
      [-99.605565, 36.592752999096646],
      [-100.00316, 36.59324999909665]
    ]
  ]
},
{
  name: 'Payne',
  rings: [
    [
      [-97.354132, 36.15907199910087],
      [-97.35387, 35.98493399910261],
      [-97.32362, 36.01087699910235],
      [-97.203592, 35.944794999103024],
      [-97.140583, 35.941990999103055],
      [-96.621097, 35.94151899910306],
      [-96.621441, 36.15992899910086],
      [-96.819005, 36.158885999100875],
      [-96.818968, 36.246477999099994],
      [-96.925069, 36.2464739991],
      [-97.140968, 36.246434999099996],
      [-97.140668, 36.15923099910088],
      [-97.354132, 36.15907199910087]
    ]
  ]
},
{
  name: 'Kiowa',
  rings: [
    [
      [-99.360226, 35.116737999111834],
      [-99.408338, 35.11648999911184],
      [-99.409291, 35.114508999111855],
      [-99.368367, 35.08786699911215],
      [-99.327788, 35.041696999112666],
      [-99.30622, 34.857631999114744],
      [-99.246462, 34.82211399911516],
      [-99.14457, 34.849539999114846],
      [-99.149114, 34.70235199911653],
      [-99.052511, 34.70155699911655],
      [-99.10315, 34.637949999117275],
      [-99.001025, 34.63787199911727],
      [-99.001107, 34.594365999117784],
      [-98.826008, 34.594412999117786],
      [-98.826041, 34.85512199911477],
      [-98.619816, 34.854952999114786],
      [-98.621054, 35.09735199911206],
      [-98.651267, 35.12418099911176],
      [-98.750344, 35.11634299911185],
      [-99.360226, 35.116737999111834]
    ]
  ]
},
{
  name: 'Oklahoma',
  rings: [
    [
      [-97.674026, 35.72596999910528],
      [-97.671383, 35.40635199910868],
      [-97.671369, 35.37715099910898],
      [-97.423566, 35.37731599910899],
      [-97.317832, 35.37728599910898],
      [-97.142114, 35.376859999108994],
      [-97.14149, 35.463976999108056],
      [-97.141072, 35.7244409991053],
      [-97.674026, 35.72596999910528]
    ]
  ]
},
{
  name: 'Bryan',
  rings: [
    [
      [-96.585355, 34.11445899912349],
      [-96.548028, 34.06330199912413],
      [-96.624803, 34.00972399912477],
      [-96.57701, 33.96440599912534],
      [-96.5946743073771, 33.88301865652465],
      [-96.590112, 33.88066499912637],
      [-96.592926, 33.830915999126994],
      [-96.572937, 33.81909799912714],
      [-96.523863, 33.81811399912715],
      [-96.502286, 33.77345999912772],
      [-96.436455, 33.78004999912762],
      [-96.403507, 33.74628899912804],
      [-96.3796608259521, 33.715530907694436],
      [-96.363135, 33.69421499912871],
      [-96.307389, 33.73500499912819],
      [-96.277269, 33.769734999127756],
      [-96.229023, 33.74802099912804],
      [-96.173025, 33.80055999912737],
      [-96.15163, 33.83194599912697],
      [-96.063924, 33.84152299912686],
      [-95.935325, 33.87509899912644],
      [-95.887491, 33.86385599912658],
      [-95.84487853407069, 33.86042190384562],
      [-95.820596, 33.858464999126646],
      [-95.78963931775229, 33.872437383798776],
      [-95.918871, 33.925056999125815],
      [-95.93815, 34.12509999912337],
      [-95.991578, 34.15680499912298],
      [-96.40764, 34.157316999122976],
      [-96.453839, 34.114405999123484],
      [-96.585355, 34.11445899912349]
    ]
  ]
}
// {
//   name: 'Oklahoma',
//   rings: [
//     [
//       [-97.674026, 35.72596999910528],
//       [-97.671383, 35.40635199910868],
//       [-97.671369, 35.37715099910898],
//       [-97.423566, 35.37731599910899],
//       [-97.317832, 35.37728599910898],
//       [-97.142114, 35.376859999108994],
//       [-97. 14149, 35.463976999108056],
//       [-97.141072, 35.7244409991053],
//       [-97.674026, 35.72596999910528]
//     ]
//   ]
// }


];

export default counties;