import { useState, useEffect } from "react";
import { connect } from "react-redux";
import NavBarContainer from "../../components/common/navbar";
import SelectViewNew from "../../components/searchFilter/SelectViewNew";
import Table from "../../components/common/table";
import { getDataLog } from "../../actions/dataLog";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { getDataError } from "../../actions/dataLog";

const getPreviousDayToCurrentRange = () => {
  const currentDate = new Date();
  const previousDate = new Date();
  previousDate.setDate(currentDate.getDate() - 1);

  const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

  return {
    fromdate: formatDate(previousDate),
    todate: formatDate(currentDate),
  };
};

function DataLog({ dataLogList, theme, getDataLog, loading, county, dataErrorList, getDataError }) {
  const [activeKey, setActiveKey] = useState('dataLog');
  const allowedCounties = ["CANADIAN", "CADDO", "OKCR", "TULSA", "OKLAHOMA"];

  const initialFilters = {
    ...getPreviousDayToCurrentRange(), 
    countyA: [], 
  };
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const { fromdate, todate } = getPreviousDayToCurrentRange();
    const initialPayload = {
      fd: fromdate,
      td: todate,
      co: "ALL",
    };
    getDataLog(initialPayload);
    getDataError(initialPayload); 
  }, [getDataLog, getDataError]);

  const inputsHandler = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const applyFilters = () => {
    const { fromdate, todate, countyA } = filters;
  
    const filterData = {
      fd: fromdate,  
      td: todate,     
      co: countyA,    
    };
  
    getDataLog(filterData);
    getDataError(filterData);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const formatDate = (dateString) => {
   if (!dateString) return "";
   const date = new Date(dateString);
   return date.toLocaleDateString("en-US", { timeZone: "UTC" });
  };

  const formatDateTime = (dateString) => {
   if (!dateString) return "";
   const date = new Date(dateString);
   return date.toLocaleString("en-US", { timeZone: "UTC" });
  };

  const cols = [
    {
      headerName: "Source",
      field: "co",
      width: 150,
      wrapHeaderText: true,
      flex: 1,
    },
    {
      headerName: "Search From Date",
      field: "sfd",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Search To Date",
      field: "std",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Record Count",
      field: "rc",
      width: 150,
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Process Type",
      field: "pt",
      width: 150,
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Run Start Time",
      field: "rst",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value),
    },
    {
      headerName: "Run Finish Time",
      field: "rft",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      flex: 1,
      valueFormatter: (params) => formatDateTime(params.value),
    },
    {
      headerName: "Run Status",
      field: "rs",
      width: 150,
      flex: 1,
      wrapHeaderText: true,
    },
  ];

  const errorCols = [
    {
      headerName: "Source",
      field: "co",
      width: 150,
      wrapHeaderText: true,
    },
    {
      headerName: "Record Detail",
      field: "rd",
      width: 150,
      wrapHeaderText: true,
    },
    {
      headerName: "Search From Date",
      field: "sfd",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Search To Date",
      field: "std",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "Error Message",
      field: "em",
      width: 200,
      wrapHeaderText: true,
      flex:2,
    },
    {
      headerName: "Exception",
      field: "ex",
      width: 200,
      wrapHeaderText: true,
      flex:1,
    },
    {
      headerName: "Run Time",
      field: "rt",
      width: 180,
      wrapHeaderText: true,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => formatDateTime(params.value),
    },
  ]

  return (
    <div className={`dashboard${theme === "dark" ? "-dark" : ""} d-flex flex-column`} style={{ flexGrow: 1 }}>
      <NavBarContainer />
      <div className="content d-flex flex-column">
        <div className="search mb-1">
          <div className="d-flex">
            <div className="mt--5">
              <label>From</label>
              <input
                style={{
                  border: "1px solid #ced4da",
                  height: "38px",
                  width: "200px",
                }}
                type="date"
                name="fromdate"
                value={filters.fromdate}
                onChange={inputsHandler}
                className={`form-control bg-transparent date-filter input-calender ${theme === "dark" ? "dark-input" : ""}`}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>To</label>
              <input
                style={{
                  border: "1px solid #ced4da",
                  height: "38px",
                  width: "200px",
                }}
                type="date"
                name="todate"
                value={filters.todate}
                onChange={inputsHandler}
                className={`form-control bg-transparent date-filter ${theme === "dark" ? "dark-input" : ""}`}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>Source</label>
              <SelectViewNew
                 className="filter"
                 options={["All", ...allowedCounties].map((item) => ({
                  value: item,
                  label: item,
                }))}
                 values={filters.countyA.map((item) => ({
                  value: item,
                  label: item,
                }))}
                 placeholder="Source"
                 searchable
                 onChange={(e) => {
                   setFilters({ ...filters, countyA: e });
                 }}
                 width={200}
                 theme={theme}
              />
            </div>
          </div>
          <div className="user-btn mt-2">
            <button className="btn btn-info mr-3" onClick={clearFilters}>
              Clear All
            </button>
            <button className="btn btn-primary" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
        <div className="data d-flex flex-column"  style={{ flexGrow: 1, height: "100%" }}>
        <div className="data d-flex flex-column">
        <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
            <Tab eventKey="dataLog" title="Logs" >
			         { loading && <span className="ml-2"> Fetching Data. Please Wait... </span>}
              <div className="digital d-flex flex-column">
                <Table cols={cols} data={dataLogList ?? []} />
              </div>
            </Tab>
            <Tab eventKey="dataError" title="Errors">
			         { loading && <span className="ml-2"> Fetching Data. Please Wait... </span>}
              <div className="digital d-flex flex-column">
                <Table cols={errorCols} data={dataErrorList ?? []} />
              </div>
            </Tab>
          </Tabs>
        </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataLogList: state.dataLog.dataLogList || [], // Ensure default empty array if undefined
  theme: state.user.theme,
  county: state.advancedSearch.county || [], // Ensure default empty array if undefined
  loading:state.dataLog.loading,
  dataErrorList: state.dataLog.dataErrorList || [],
});

const mapDispatchToProps = (dispatch) => ({
  getDataLog: (filters) => dispatch(getDataLog(filters)),
  getDataError:(filters) => dispatch(getDataError(filters)),
});

const DataLogContainer = connect(mapStateToProps, mapDispatchToProps)(DataLog);

export default DataLogContainer;
