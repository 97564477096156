/**
 * OKCR Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/advancedSearch";
import * as instrumentApis from "../apis/instrument";


export function* getListing({ payload }) {
  try {
    const res = yield call(api.doGetInstrumentList, payload);
    if (res) {
      yield put({
        type: types.ADVANCED_SEARCH_SUCCESS,
        payload: { instrumentList: res },
      });
    } else {
      yield put({
        type: types.ADVANCED_SEARCH_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADVANCED_SEARCH_FAILURE,
      payload: { err },
    });
  }
}

export function* getListingNew({ payload }) {
  try {
    const res = yield call(api.doGetInstrumentListNew, payload);
    if (res) {
      yield put({
        type: types.TRIGGER_LOCAL_GRID_FILTER
      });
      if(res.length) {
        yield put({
          type: types.GET_REGULATORY_APPS_ORDERS_DATA_REQUEST
        });
      }
      yield put({
        type: types.ADVANCED_SEARCH_NEW_SUCCESS,
        payload: { payload, instrumentList: res },
      });
    } else {
      yield put({
        type: types.ADVANCED_SEARCH_NEW_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADVANCED_SEARCH_NEW_FAILURE,
      payload: { err },
    });
  }
}

export function* getInstrumentTypes() {
  try {
    const res = yield call(api.doGetInstrumentTypes);
    if (res) {
      yield put({
        type: types.INSTRUMENT_TYPE_LIST_SUCCESS,
        payload: { instrumentTypes: res },
      });
    } else {
      yield put({
        type: types.INSTRUMENT_TYPE_LIST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.INSTRUMENT_TYPE_LIST_FAILURE,
      payload: { err },
    });
  }
}

export function* getPdfComments({payload}) {
  try {
    const res = yield call(api.doGetPdfComments, payload);
    if (res) {
      yield put({
        type: types.GET_PDF_COMMENTS_SUCCESS,
        payload: res,
      });
    } else {
      yield put({
        type: types.GET_PDF_COMMENTS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_PDF_COMMENTS_FAILURE,
      payload: { err },
    });
  }
}

export function* addPdfComments({payload}) {
  try {
    const res = yield call(api.doAddPdfComments, payload);
    if (res) {
      yield put({
        type: types.ADD_PDF_COMMENTS_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: types.ADD_PDF_COMMENTS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADD_PDF_COMMENTS_FAILURE,
      payload: { err },
    });
  }
}

export function* fetchPreviousFiltersSaga() {
  try {
    const res = yield call(api.doFetchPreviousFilters);
    if (res) {
      yield put({
        type: types.FETCH_PREVIOUS_FILTERS_SUCCESS,
        payload: res,
      });
    } else {
      yield put({
        type: types.FETCH_PREVIOUS_FILTERS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_PREVIOUS_FILTERS_FAILURE,
      payload: { err },
    });
  }
}

// Get Instrument document
export function* getInstrumentDocumentSaga({ payload: { instrument } }) {
  try {
    const req = { county: instrument.co };
    let apiMethod;
    if(instrument.hd) {
      req['instrument'] = instrument.iid;
      apiMethod = instrumentApis.doGetInstrumentDocument;
    } else {
      req['instrument_id'] = instrument.iid;
      apiMethod = api.doGetInstrumentDocument;
    }
    const pdfData = yield call(apiMethod, req);
    if (pdfData) {
      yield put({
        type: instrument.hd ? types.GET_ADV_INST_DOCUMENT_SUCCESS : types.DOWNLOAD_ADV_INST_DOCUMENT_SUCCESS,
        payload: { instrument, pdfData },
      });
    } else {
      yield put({
        type: types.GET_ADV_INST_DOCUMENT_FAILURE,
        payload: { err: { message: "Something went wrong" }, instrument },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_ADV_INST_DOCUMENT_FAILURE,
      payload: { err: { message: "Something went wrong" } , instrument},
    });
  }
}

export function* reportIssuePDF({ payload }) {
  try {
    const res = yield call(api.doreportIssuePDF, payload);
    if (res) {
      yield put({
        type: types.REPORT_ERROR_PDF_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: types.REPORT_ERROR_PDF_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.REPORT_ERROR_PDF_FAILURE,
      payload: { err },
    });
  }
}

export function* getErrorReportData({ payload: { id } }) {
  try {
    const res = yield call(api.doGetErrorReportData, { rid: id });
    if (res[0]) {
      yield put({
        type: types.GET_ERROR_REPORT_DATA_SUCCESS,
        payload: res[0],
      });
    } else {
      yield put({
        type: types.GET_ERROR_REPORT_DATA_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_ERROR_REPORT_DATA_FAILURE,
      payload: { err },
    });
  }
}

export function* fetchRegulatoryAppsOrdersSaga() {
  try {
    const res = yield call(api.doGetRegulatoryAppsOrders);  // Call the API
    if (res) {
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS,  // Dispatch success action
        payload: { regulatoryAppsOrders: res },  // Pass the data as payload
      });
    } else {
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE,  // Dispatch failure action
        payload: { err: { message: "Something went wrong" } },  // Error message in case of failure
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE,  // Dispatch failure action
      payload: { err },  // Pass the caught error
    });
  }
}


// OKCR Saga
export default function* advancedSearchSaga() {
  yield all([
    takeEvery(types.ADVANCED_SEARCH_REQUEST, getListing),
    takeEvery(types.ADVANCED_SEARCH_NEW_REQUEST, getListingNew),
    takeEvery(types.INSTRUMENT_TYPE_LIST_REQUEST, getInstrumentTypes),
    takeEvery(types.GET_ADV_INST_DOCUMENT_REQUEST, getInstrumentDocumentSaga),
    takeEvery(types.DOWNLOAD_ADV_INST_DOCUMENT_REQUEST, getInstrumentDocumentSaga),
    takeEvery(types.GET_PDF_COMMENTS_REQUEST, getPdfComments),
    takeEvery(types.ADD_PDF_COMMENTS_REQUEST, addPdfComments),
    takeEvery(types.FETCH_PREVIOUS_FILTERS_REQUEST, fetchPreviousFiltersSaga),
    takeEvery(types.REPORT_ERROR_PDF_REQUEST, reportIssuePDF),
    takeEvery(types.GET_ERROR_REPORT_DATA_REQUEST, getErrorReportData),
    takeEvery(types.GET_REGULATORY_APPS_ORDERS_DATA_REQUEST, fetchRegulatoryAppsOrdersSaga),
  ]);
}